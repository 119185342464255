<template>
  <div>
    <b-card-actions
      no-actions
      no-body
      title="Payments"
    >
      <div>
        <b-table
          hover
          class="table_payments"
          responsive="sm"
          :items="payments"
          :fields="fields_payments_mobile"
        >
          <template #cell(request_date)="row">
            {{ row.item.request_date ? moment(row.item.request_date).format('MMM Do YYYY') : '' }}
          </template>
          <template #cell(show_details)="row">
            <b-button
              size="sm"
              class="mr-1"
              @click="row.toggleDetails"
            >
              {{ row.detailsShowing ? 'Hide' : 'Details' }}
            </b-button>
          </template>
          <template #row-details="row">
            <b-row class="mb-1">
              <b-col
                md="3"
                sm="3"
                class="text-sm-right"
              >
                <b>Ticket</b>
              </b-col>
              <b-col md="3">
                {{ row.item.ticket }}
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col
                md="3"
                sm="3"
                class="text-sm-right"
              >
                <b>Payment Type:</b>
              </b-col>
              <b-col md="3">
                {{ row.item.payment_type }}
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col
                sm="3"
                class="text-sm-right"
              >
                <b>Price:</b>
              </b-col>
              <b-col>{{ row.item.price }}</b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col
                sm="3"
                class="text-sm-right"
              >
                <b>Lessons Count:</b>
              </b-col>
              <b-col>{{ row.item.lessons_count }}</b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col
                sm="3"
                class="text-sm-right"
              >
                <b>Payment State:</b>
              </b-col>
              <b-col>{{ row.item.payment_state }}</b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col
                sm="3"
                class="text-sm-right"
              >
                <b>Total Amount:</b>
              </b-col>
              <b-col>{{ row.item.total_amount }}</b-col>
            </b-row>
            <b-button
              size="sm"
              @click="row.toggleDetails"
            >
              Hide Details
            </b-button>
          </template>
        </b-table>
      </div>
    </b-card-actions>
  </div>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { BTable } from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BCardActions,
  },
  data() {
    return {
      colorField: 'danger',
      desktop: {},
      payments: [],
      fields_payments_mobile: [
        /*
        {
          key: 'payment_id',
          label: 'Payment ID',
          sortable: true,
        },
        */
        {
          key: 'request_date',
          label: 'Date',
          sortable: true,
        },
        {
          key: 'payment_type',
          label: 'Payment Type',
          sortable: true,
        },
        {
          key: 'ticket',
          label: 'Ticket',
          sortable: true,
        },
        {
          key: 'price',
          label: 'Price',
          sortable: true,
        },
        {
          key: 'lessons_count',
          label: 'Lessons Count',
          sortable: true,
        },
        {
          key: 'payment_state',
          label: 'State',
          sortable: true,
          variant: this.colorField
        },
        {
          key: 'total_amount',
          label: 'Total amount',
          sortable: true,
        },
      ],
    }
  },
  computed: {},
  created() {
    this.$http.get('/api/profile/get_payments', {
    }).then(res => {
      // ? const data = JSON.stringify(res.data)
      // ? console.log('refresh tickets')
      // ? console.log(data)
      this.payments = res.data
    })
  },
}
</script>
