<template>
  <div>
    <div v-if="!isMobile()">
      <paymentsDesktop />
    </div>
    <div v-else>
      <paymentsMobile />
    </div>
  </div>
</template>

<script>
import paymentsDesktop from './paymentsDesktop.vue'
import paymentsMobile from './paymentsMobile.vue'

export default {
  components: {
    paymentsDesktop,
    paymentsMobile,
  },
  data() {
    return {
    }
  },
  methods: {
    isMobile() {
      if (document.documentElement.clientWidth <= 800) {
        return false
      }
      return true
    },
  },
}
</script>
